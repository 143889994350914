import React, { useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import PrivateRoute from '../utils/PrivateRoute';
import Header from "../components/Header"
import LoginPage from "./LoginPage";
import CreateClientPage from "./CreateClientPage";
import CreateUserPage from "./CreateUserPage";
import ContactPage from "./ContactPage";
import AlertComponent from "../components/AlertComponent";
import ClientPage from "./ClientPage";
import AdminPage from "./AdminPage";

function App() {
  const [title, updateTitle] = useState(null);
  const [errorMessage, updateErrorMessage] = useState(null);
  return (
      <Router>
        <div className="App">
          <Header title={title} />
          <div className="d-flex align-items-center flex-column">
          <Switch>
            <Route exact path="/create-client" >
              <CreateClientPage showError={updateErrorMessage} updateTitle={updateTitle}/>
            </Route>
            <Route exact path="/create-user" >
              <CreateUserPage showError={updateErrorMessage} updateTitle={updateTitle}/>
            </Route>
            <Route exact path="/">
              <LoginPage showError={updateErrorMessage} updateTitle={updateTitle}/>
            </Route>
            <Route path="/contact/">
              <ContactPage showError={updateErrorMessage} updateTitle={updateTitle}/>
            </Route>
            <PrivateRoute exact path="/admin">
              <AdminPage/>
            </PrivateRoute>
            <PrivateRoute exact path="/client">
              <ClientPage/>
            </PrivateRoute>
          </Switch>
          <AlertComponent errorMessage={errorMessage} hideError={updateErrorMessage}/>
        </div>
        </div>
      </Router>
  );
}

export default App;
