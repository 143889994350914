import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../constants/apiConstants";

const ContactPage = (props) => {
  const search = useLocation().search;
  const param_id = new URLSearchParams(search).get("id");
  const param_sdId = new URLSearchParams(search).get("sdId");
  const param_contactName = new URLSearchParams(search).get("contactName");
  const param_contactPhone = new URLSearchParams(search).get("contactPhone");
  const param_contactEmail = new URLSearchParams(search).get("contactEmail");
  const [contactId, setContactId] = useState(param_id);
  const [dealerShipId, setDealerShipId] = useState(param_sdId);
  const [contactName, setContactName] = useState(param_contactName);
  const [contactPhone, setContactPhone] = useState(param_contactPhone);
  const [contactEmail, setContactEmail] = useState(param_contactEmail);
  const [successMessage, setSuccessMessage] = useState("");

  console.log("id", param_id);
  console.log("sdId", param_sdId);
  console.log("contactName", param_contactName);
  console.log("contactPhone", param_contactPhone);
  console.log("contactEmail", param_contactEmail);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const valid = contactName.length && contactPhone.length && contactEmail.length;
    if (!valid) {
      props.showError("Please enter valid contact name, phone and email");
      return;
    }
    props.showError(null);
    const payload = {
      contactId,
      dealerShipId,
      contactName,
      contactPhone,
      contactEmail,
    };
    axios
      .post(`${API_BASE_URL}/api/contact-create`, payload)
      .then(function (response) {
        if (response.status === 200) {
          const contactResponse = response.data;
          setSuccessMessage(`Contact Update successful. Contact Id: ${contactResponse.Message}`);
          window.close();
          props.showError(null);
        } else {
          props.showError("Some error ocurred");
        }
      })
      .catch(function (error) {
        console.log("Create contact Api error", error);
      });
  };

  function renderId() {
    if (contactId) {
      return (
        <div className="form-group text-left">
          <label htmlFor="exampleInputContactId1">Id:</label>
          <input
            type="text"
            className="form-control"
            id="contactId"
            aria-describedby="contactIdHelp"
            placeholder="Enter contact id"
            value={contactId}
            onChange={(e) => setContactId(e.target.value)}
          />
        </div>
      );
    }
  }

  function renderDealerShipId() {
    if (dealerShipId) {
      return (
        <div className="form-group text-left">
          <label htmlFor="exampleInputDealershipId1">Dealership Id:</label>
          <input
            type="text"
            className="form-control"
            id="dealershipId"
            aria-describedby="dealershipIdHelp"
            placeholder="Enter dealership id"
            value={dealerShipId}
            onChange={(e) => setDealerShipId(e.target.value)}
          />
        </div>
      );
    }
  }

  return (
    <div className="card col-12 col-lg-6 mt-2 hv-center">
      <span>{successMessage}</span>
      <form onSubmit={handleSubmit}>
        {renderId()}
        {renderDealerShipId()}
        <div className="form-group text-left">
          <label htmlFor="exampleInputContactName1">Contact Name:</label>
          <input
            type="text"
            className="form-control"
            id="contactName"
            aria-describedby="contactNameHelp"
            placeholder="Enter contact name"
            value={contactName}
            onChange={(e) => setContactName(e.target.value)}
          />
        </div>
        <div className="form-group text-left">
          <label htmlFor="exampleInputContactPhone1">Contact Phone:</label>
          <input
            type="text"
            className="form-control"
            id="contactPhone"
            aria-describedby="contactPhoneHelp"
            placeholder="Enter contact phone"
            value={contactPhone}
            onChange={(e) => setContactPhone(e.target.value)}
          />
        </div>
        <div className="form-group text-left">
          <label htmlFor="exampleInputContactName1">Contact Email:</label>
          <input
            type="text"
            className="form-control"
            id="contactEmail"
            aria-describedby="contactEmailHelp"
            placeholder="Enter contact email"
            value={contactEmail}
            onChange={(e) => setContactEmail(e.target.value)}
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Update
        </button>
      </form>
    </div>
  );
};

export default ContactPage;
