import React, { useState } from "react";
import axios from "axios";
import "./LoginPage.css";
import { API_BASE_URL, USER_ID, USER_LOGIN, DEALERSHIP_ID, DEALERSHIP_DOC_ID } from "../constants/apiConstants";
import { withRouter } from "react-router-dom";

const LoginPage = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  function validateForm() {
    return email.length && password.length;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const valid = email.length && password.length;
    if (!valid) {
      props.showError("Please enter valid username and password");
      return;
    }
    props.showError(null);
    const payload = {
      email: email,
      password: password,
    };
    axios
      .post(`${API_BASE_URL}/api/login`, payload)
      .then(function (response) {
        if (response.status === 200) {
          const loginResponse = response.data;
          console.log("loginResponse", loginResponse);
          setSuccessMessage("Registration successful. Redirecting to home page..");
          localStorage.setItem(USER_ID, loginResponse.userId);
          localStorage.setItem(USER_LOGIN, loginResponse.userEmail);
          if (loginResponse.admin) {
            redirectToAdminPage();
          } else {
            localStorage.setItem(DEALERSHIP_ID, loginResponse.dealership);
            localStorage.setItem(DEALERSHIP_DOC_ID, loginResponse.dealerDocId);
            redirectToClientPage();
          }
          props.showError(null);
        } else {
          props.showError("Some error ocurred");
        }
      })
      .catch(function (error) {
        console.log("Login Api error", error);
      });
  };

  const redirectToAdminPage = () => {
    props.updateTitle("Admin - Portal");
    props.history.push("/admin");
  };

  const redirectToClientPage = () => {
    props.updateTitle("Client Portal");
    props.history.push("/client");
  };

  return (
    <div className="card col-12 col-lg-4 login-card mt-2 hv-center">
      <form onSubmit={handleSubmit}>
        <div className="form-group text-left">
          <label htmlFor="exampleInputEmail1">Email:</label>
          <input
            type="email"
            className="form-control"
            id="email"
            aria-describedby="emailHelp"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="form-group text-left">
          <label htmlFor="exampleInputPassword1">Password:</label>
          <input
            type="password"
            className="form-control"
            id="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit" className="btn btn-primary" disabled={!validateForm()}>
          Login
        </button>
      </form>
    </div>
  );
};

export default withRouter(LoginPage);
