import React, { useState } from "react";
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, NavbarText } from "reactstrap";
import { withRouter } from "react-router-dom";
import { USER_ID, USER_LOGIN } from "../constants/apiConstants";
import logo from "../RecallRabbitLogo.jpg";

const Header = (props) => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);

  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  let title = capitalize(props.location.pathname.substring(1, props.location.pathname.length));
  let user = localStorage.getItem(USER_LOGIN);
  if (props.location.pathname === "/") {
    title = "Welcome";
  }

  function renderLogout() {
    if (
      props.location.pathname === "/admin" ||
      props.location.pathname === "/client" ||
      props.location.pathname === "/create-client" ||
      props.location.pathname === "/create-user"
    ) {
      return (
        <div className="ml-auto">
          <button className="btn btn-danger" onClick={() => handleLogout()}>
            Logout
          </button>
        </div>
      );
    }
  }

  function renderCreateClient() {
    if (props.location.pathname === "/admin") {
      return <NavLink href="/create-client">Create Client</NavLink>;
    } else if (props.location.pathname === "/create-client") {
      return <NavLink href="/admin">Client List</NavLink>;
    }
  }

  function renderCreateUser() {
    if (props.location.pathname === "/admin") {
      return <NavLink href="/create-user">Create User</NavLink>;
    } else if (props.location.pathname === "/create-user") {
      return <NavLink href="/admin">Client List</NavLink>;
    }
  }

  function renderLogin() {
    if (props.location.pathname === "/") {
      return <NavLink href="/">Login</NavLink>;
    }
  }

  function handleLogout() {
    localStorage.removeItem(USER_ID);
    localStorage.removeItem(USER_LOGIN);
    props.history.push("/");
  }

  return (
    <Navbar color="light" light expand="md">
      <NavbarBrand href="/">
        <img src={logo} className="img-thumbnail" alt="logo" style={{ width: "80px" }} />
        Recall Rabbit
      </NavbarBrand>
      <NavbarText>
        <span style={{ marginRight: "4px" }}></span>
      </NavbarText>
      <NavbarText>
        <span className="h3">{user}</span>
      </NavbarText>
      <NavbarText>
        <span style={{ marginRight: "4px", marginLeft: "4px" }}></span>
      </NavbarText>
      <NavbarText>
        <span className="h3">{props.title || title}</span>
      </NavbarText>
      <NavbarToggler onClick={toggleNavbar} className="mr-2" />
      <Collapse isOpen={collapsed} navbar>
        <Nav className="mr-auto" navbar>
          <NavItem>{renderCreateClient()}</NavItem>
        </Nav>
        <Nav className="mr-auto" navbar>
          <NavItem>{renderCreateUser()}</NavItem>
        </Nav>
        <Nav className="mr-auto" navbar>
          <NavItem>{renderLogin()}</NavItem>
        </Nav>
        <Nav className="mr-auto" navbar>
          <NavItem>{renderLogout()}</NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default withRouter(Header);
