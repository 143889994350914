import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { API_BASE_URL } from "../constants/apiConstants";
import axios from "axios";
import "./CreateClientPage.css";

const CreateClientPage = (props) => {
  const [make, setMake] = useState("");
  const [name, setName] = useState("");
  const [zipcode, setZipCode] = useState("");
  const [spreadsheetid, setSpreadsheetId] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const valid = make.length && name.length && zipcode.length && spreadsheetid.length;
    if (!valid) {
      props.showError("Please enter valid username and password");
      return;
    }
    props.showError(null);
    const payload = {
      make,
      name,
      zipcode,
      spreadsheetid,
    };
    axios
      .post(`${API_BASE_URL}/api/create-client`, payload)
      .then(function (response) {
        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.Result) {
            setSuccessMessage(responseData.Message);
            props.updateTitle("Admin - Portal");
            props.history.push("/admin");
            props.showError(null);
          } else {
            props.showError(responseData.Message);
          }
        } else {
          props.showError("Some error ocurred");
        }
      })
      .catch(function (error) {
        console.log("Login Api error", error);
      });
  };

  return (
    <div className="card col-12 col-lg-4 client-card mt-2 hv-center">
      <form onSubmit={handleSubmit}>
        <div className="form-group text-left">
          <label htmlFor="exampleInputName1">Name:</label>
          <input
            type="text"
            className="form-control"
            id="name"
            aria-describedby="nameHelp"
            placeholder="Enter cleint Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="form-group text-left">
          <label htmlFor="exampleInputMake1">Make:</label>
          <input
            type="text"
            className="form-control"
            id="make"
            aria-describedby="makeHelp"
            placeholder="Enter client make"
            value={make}
            onChange={(e) => setMake(e.target.value)}
          />
        </div>
        <div className="form-group text-left">
          <label htmlFor="exampleInputZipCode1">Zip-Code:</label>
          <input
            type="text"
            className="form-control"
            id="zipcode"
            aria-describedby="zipcodeHelp"
            placeholder="Enter client Zip-Code"
            value={zipcode}
            onChange={(e) => setZipCode(e.target.value)}
          />
        </div>
        <div className="form-group text-left">
          <label htmlFor="exampleInputspreadsheetid1">Spreadsheet Id:</label>
          <input
            type="text"
            className="form-control"
            id="spreadsheetid"
            aria-describedby="spreadsheetidHelp"
            placeholder="Enter client Spreadsheet Id"
            value={spreadsheetid}
            onChange={(e) => setSpreadsheetId(e.target.value)}
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Create
        </button>
      </form>
    </div>
  );
};

export default withRouter(CreateClientPage);
