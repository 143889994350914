import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { USER_ID, API_BASE_URL, DEALERSHIP_ID, DEALERSHIP_DOC_ID } from "../constants/apiConstants";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import axios from "axios";

const Clientpage = (props) => {
  const dealershipDocId = localStorage.getItem(DEALERSHIP_DOC_ID);
  const dealershipId = localStorage.getItem(DEALERSHIP_ID);
  const [clientListings, setClientListings] = useState([]);
  // const [refreshKey, setRefreshKey] = useState(0);
  const [dealerName, setDealerName] = useState("");

  const fetchClientData = async () => {
    const response = await axios.get(`${API_BASE_URL}/api/client/${dealershipId}/${dealershipDocId}`).catch(function (error) {});
    if (response.status === 200) {
      if (response.data.Result) {
        const { listings, dealerName } = response.data;
        setDealerName(dealerName);
        setClientListings(listings);
      }
    }
  };

  useEffect(() => {
    fetchClientData();
  }, []);

  const columns = [
    {
      dataField: "build.year",
      text: "Year",
      sort: true,
      filter: textFilter(),
      editable: false,
    },
    {
      dataField: "vehicle",
      text: "Vehicle",
      sort: true,
      filter: textFilter(),
      editable: false,
    },
    {
      dataField: "vin",
      text: "VIN #",
      sort: true,
      filter: textFilter(),
      editable: false,
    },
    {
      dataField: "recall_number",
      text: "Recall #",
      sort: true,
      filter: textFilter(),
      editable: false,
    },
    {
      dataField: "recall_title",
      text: "Recall Title",
      sort: true,
      filter: textFilter(),
      editable: false,
    },
    {
      dataField: "dealer.name",
      text: "Location",
      sort: true,
      filter: textFilter(),
      editable: false,
    },
    {
      dataField: "dealer.phone",
      text: "Contact Info",
      sort: true,
      filter: textFilter(),
      editable: false,
    },
    {
      dataField: "zipcode",
      text: "Address",
      sort: true,
      filter: textFilter(),
      editable: false,
    },
  ];

  return (
    <div>
      <div className="mt-2">Dealer {dealerName}</div>
      <BootstrapTable
        bootstrap4
        classes="table-responsive"
        hover
        keyField="vin"
        data={clientListings}
        columns={columns}
        filter={filterFactory()}
      />
    </div>
  );
};

export default withRouter(Clientpage);
