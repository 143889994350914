import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { USER_ID, API_BASE_URL } from "../constants/apiConstants";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import cellEditFactory from "react-bootstrap-table2-editor";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import CSVReader from "react-csv-reader";
import stringify from "csv-stringify";
import "./AdminPage.css";

import axios from "axios";

const AdminPage = (props) => {
  const userId = localStorage.getItem(USER_ID);
  const [refreshKey, setRefreshKey] = useState(0);
  const [clientList, setClientList] = useState([]);
  const [showImportModal, setshowImportModal] = useState(false);
  const [showExportModal, setshowExportModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("---Loading---");
  const [fileName, setFileName] = useState("");
  const [messageResult, setMessageResult] = useState("");

  const [recallVins, setRecallVins] = useState(0);

  const toggle = () => setshowImportModal(!showImportModal);
  const toggleResultMessage = () => setshowExportModal(!showExportModal);
  const toggleLoading = (show, message) => {
    setShowLoading(show);
    if (message) {
      setLoadingMessage(message);
    }
  };

  const fetchClientsData = async () => {
    const response = await axios.get(`${API_BASE_URL}/api/client-list/${userId}`).catch(function (error) {});
    if (response.status === 200) {
      const clientList = response.data.clientsList;
      setClientList(clientList);
    }
  };

  useEffect(() => {
    fetchClientsData();
  }, [refreshKey]);

  function dateFormatter(cell, row) {
    console.log("cell", cell);
    if (cell) {
      return <span>{new Date(cell._seconds * 1000).toLocaleDateString("en-US")}</span>;
    } else {
      return <span></span>;
    }
  }

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    //transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
  };

  function buttonImportFormatter(cell, row) {
    if (row.recall_evaluation_in_progress) {
      return (
        <div className="container-input">
          <CSVReader
            label="Select CSV"
            cssClass="react-csv-input"
            onFileLoaded={(data, fileInfo) => handleFileRead(data, fileInfo, row)}
            noDrag
            onError={handleFileError}
            parserOptions={papaparseOptions}
            inputId={row.ClientId}
            inputName={row.ClientId}
            inputStyle={{ color: "darkblue" }}
          />
        </div>
      );
      // return <input type="file" id={row.ClientId} color="primary" onChange={(e) => fileImportHandler(e, row)}></input>;
    } else {
      return <span></span>;
    }
  }

  const GenerateSpreadsheet = async (e, column, columnIndex, row, rowIndex) => {
    toggleLoading(true, "---Generating---");
    try {
      const response = await axios
        .get(`${API_BASE_URL}/api/generate-spreadsheet/${row.ClientId}`)
        .catch(function (error) {});
      toggleLoading(false);
      if (response && response.status === 200) {
        console.log("response spreadsheet", response.data);
        setMessageResult(response.data.Message);
        toggleResultMessage();
        setRefreshKey(refreshKey + 1);
      }
    } catch (error) {
      setMessageResult("Error");
      toggleResultMessage();
    }
  };

  const handleFileError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  const handleFileRead = async (readData, fileInfo, row) => {
    toggleLoading(true, "Reading File");
    try {
      const recallData = readData.filter((dataRow) => dataRow.RecallState === "Available");
      //Now handle the vin data response and update the Database
      const VINSRecallInfo = recallData.map((rVIN) => {
        let recallNumber = rVIN.RecallOEMNumber;
        if (rVIN.RecallNHTSANumber) {
          recallNumber = `${recallNumber}-${rVIN.RecallNHTSANumber}`;
        }
        return {
          vin: rVIN.VehicleVIN,
          recallNumber: recallNumber,
          recallTitle: rVIN.RecallTitle,
        };
      });
      const response = await axios
        .post(`${API_BASE_URL}/api/update-vin-recalls/${row.make}/${row.zipcode}/${row.ClientId}`, VINSRecallInfo)
        .catch(function (error) {});
      console.log("response", response.data);
      setFileName(fileInfo.name);
      setRecallVins(recallData.length);
      toggleLoading(false);
      toggle();
      setClientList([]);
      setRefreshKey(refreshKey + 1);
    } catch (error) {
      toggleLoading(false);
      setMessageResult("Error");
      toggleResultMessage();
    }
  };

  const ExportCSV = async (e, column, columnIndex, row, rowIndex) => {
    toggleLoading(true, "Checking for New VINs");
    const clientName = row.name;
    const response = await axios
      .get(`${API_BASE_URL}/api/make-vins/${row.make}/${row.zipcode}/${clientName}/${row.ClientId}`)
      .catch(function (error) {});
    toggleLoading(false);
    if (response && response.status === 200) {
      const vinList = response.data;
      if (vinList && vinList.length > 0) {
        stringify(
          vinList,
          {
            header: true,
            columns: ['DEALER_CODE', 'VIN']
          },
          function (err, output) {
            const element = document.createElement("a");
            const file = new Blob([output], { type: "text/csv" });
            element.href = URL.createObjectURL(file);
            const today = new Date();
            const today_string = today.toISOString().substring(0, 10);
            console.log("today_string", today_string);
            element.download = `${clientName}-${today_string}.csv`;
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
            setRefreshKey(refreshKey + 1);
          }
        );
      } else {
        setMessageResult("No new VINs to review!");
        toggleResultMessage();
      }
    }
  };

  const columns = [
    {
      dataField: "make",
      text: "Make",
      sort: true,
      filter: textFilter(),
      editable: false,
    },
    {
      dataField: "ClientId",
      text: "Client Id",
      sort: true,
      filter: textFilter(),
      editable: false,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      filter: textFilter(),
      editable: false,
    },
    {
      dataField: "zipcode",
      text: "Zipcode",
      sort: true,
      filter: textFilter(),
      editable: false,
    },
    {
      dataField: "spreadsheet_id",
      text: "SpreadSheet Id",
      sort: true,
      formatter: (cellContent, row) => (
        <div className="checkbox disabled">
          <label>
            <input type="checkbox" checked={row.spreadsheet_id} disabled />
          </label>
        </div>
      ),
      title: (cell, row, rowIndex, colIndex) => `Spreadsheet Id: ${cell}`,
      editable: false,
    },
    {
      dataField: "used_vins",
      formatter: (cell, row) => <span>{cell.length}</span>,
      text: "# Used Vins",
      editable: false,
    },
    {
      dataField: "current_vins",
      formatter: (cell, row) => <span>{cell.length}</span>,
      text: "# Current Vins",
      editable: false,
    },
    {
      dataField: "radius",
      text: "Mile Radius",
    },
    {
      dataField: "Fetch Script",
      formatter: (cell, row) => (
        <span>
          python ../dealerships/fetcher.py {row.make} {row.make}_listings "{row.name}" {row.zipcode} {row.radius} desc
        </span>
      ),
      text: "Fetch Script",
      editable: false,
    },
    {
      dataField: "btnExportCSV",
      isDummyField: true,
      text: "Export VINs CSV",
      formatter: (cellContent, row) => <Button color="info">Export</Button>,
      events: {
        onClick: ExportCSV,
      },
      editable: false,
    },
    {
      dataField: "recall_evaluation_in_progress",
      text: "Recall Evaluation in Progress",
      formatter: (cellContent, row) => (
        <div className="checkbox disabled">
          <label>
            <input type="checkbox" checked={row.recall_evaluation_in_progress} disabled />
          </label>
        </div>
      ),
      editable: false,
    },
    {
      dataField: "btnImportVINSCSV",
      isDummyField: true,
      text: "Import VINs Recalls Result",
      formatter: buttonImportFormatter,
      // events: {
      //   onClick: ImportVINSCSV,
      // },
      editable: false,
    },
    {
      dataField: "last_spreadsheet_export",
      text: "Last Spreadsheet Export",
      formatter: dateFormatter,
      editable: false,
    },
    {
      dataField: "btnGenerateSpreadsheet",
      isDummyField: true,
      text: "Generate Spreadsheet Report",
      formatter: (cellContent, row) => <Button color="success">Generate</Button>,
      events: {
        onClick: GenerateSpreadsheet,
      },
      editable: false,
    },
  ];

  return (
    <div>
      <div className="mt-2">Client List</div>
      <div className="container-xl">
        <BootstrapTable
          bootstrap4
          classes="table-responsive"
          hover
          keyField="ClientId"
          data={clientList}
          columns={columns}
          filter={filterFactory()}
          cellEdit={cellEditFactory({
            mode: "click",
            afterSaveCell: (oldValue, newValue, row, column) => {
              // setGridColumns(columns);
            },
          })}
        />
      </div>
      <Modal isOpen={showImportModal}>
        <ModalHeader icon="success" toggle={toggle}>
          {fileName} has been loaded!
        </ModalHeader>
        <ModalBody>With {recallVins} Vins with recall info!</ModalBody>
      </Modal>
      <Modal isOpen={showExportModal}>
        <ModalHeader icon="success" toggle={toggleResultMessage}>
          Result
        </ModalHeader>
        <ModalBody> {messageResult}</ModalBody>
      </Modal>
      <Modal isOpen={showLoading} toggle={toggleLoading} keyboard={false}>
        <ModalBody>{loadingMessage}</ModalBody>
      </Modal>
    </div>
  );
};

export default withRouter(AdminPage);
