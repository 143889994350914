import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import "./CreateUserPage.css";
import { API_BASE_URL } from "../constants/apiConstants";

const CreateUserPage = (props) => {
  const [userId, setUserId] = useState("");
  const [name, setName] = useState("");
  const [active, setActive] = useState(true);
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const valid = name.length&& name.length;
    if (!valid) {
      props.showError("Please enter valid name and User Id");
      return;
    }
    props.showError(null);
    const payload = {
      userId,
      active,
      name,
    };
    axios
      .post(`${API_BASE_URL}/api/create-admin`, payload)
      .then(function (response) {
        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.Result) {
            setSuccessMessage("Admin Registration successful. Redirecting to home page..");
            props.updateTitle("Admin - Portal");
            props.history.push("/admin");
            props.showError(null);
          } else {
            props.showError(responseData.Message);
          }
        } else {
          props.showError("Some error ocurred");
        }
      })
      .catch(function (error) {
        console.log("Create Admin Api error", error);
      });
  };
  return (
    <div className="card col-12 col-lg-4 login-card mt-2 hv-center">
      <form onSubmit={handleSubmit}>
      <div className="form-group text-left">
          <label htmlFor="exampleInputName1">User Id: </label>
          <input
            type="text"
            className="form-control"
            id="userId"
            aria-describedby="UserIdHelp"
            placeholder="Enter User Id"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
          />
        </div>
        <div className="form-group text-left">
          <label htmlFor="exampleInputName1">Name: </label>
          <input
            type="text"
            className="form-control"
            id="Name"
            aria-describedby="NameHelp"
            placeholder="Enter Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="form-group text-left">
          <label htmlFor="exampleInputActive1">Active:</label>
          <input
            type="checkbox"
            className="form-control"
            id="active"
            aria-describedby="activeHelp"
            placeholder="Is active?"
            value={active}
            onChange={(e) => setActive(e.target.value)}
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Create
        </button>
      </form>
    </div>
  );
};

export default withRouter(CreateUserPage);
